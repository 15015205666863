import { createRouter, createWebHistory } from "vue-router";
import Main from "@/views/Main.vue";

const routes = [
    {
        path: "/",
        name: "main",
        component: Main,
        meta: {
            title: "Главная",
        },
    },
    // {
    //     path: "/reservation",
    //     name: "reservation",
    //     component: () => import("@/views/Reservation.vue"),
    // },
    {
        path: "/account",
        name: "account",
        component: () => import("@/views/Account.vue"),
        meta: {
            title: "Личный кабинет",
        },
    },
    {
        path: "/nikahi",
        name: "nikahi",
        component: () => import("@/views/Nikahi.vue"),
        meta: {
            title: "Никахи",
        },
    },
    {
        path: "/loyalty-program",
        name: "loyalty-program",
        component: () => import("@/views/LoyaltyProgram.vue"),
        meta: {
            title: "Программа лояльности",
        },
    },
    {
        path: "/sadaka",
        // redirect: 'https://zaman.tubatay.com/',
        beforeEnter: () => {
            window.location.href = "https://zaman.tubatay.com/";
        },
        name: "sadaka",
        component: () => import("@/views/SadakaView.vue"),
        meta: {
            title: "Садака",
        },
    },
    // {
    //     path: "/sadaka",
    //     name: "sadaka",
    //     component: () => import("@/views/SadakaView.vue"),
    // },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "active",
    scrollBehavior() {
        return { top: 0 };
    },
});

router.beforeEach((to, from, next) => {
    document.title = "«Заман» - кафе-музей национальной, татарской кухни | " + to.meta.title;
    next();
});

export default router;
