<template>
    <div class="discounts" id="disc">
        <div class="discounts__container center-wrapper">
            <h2 class="title">{{ title }}</h2>
            <div class="dishes">
                <div class="dish" v-for="discount in discounts" :key="discount.id" :id="discount.id">
                    <div class="dish__view">
                        <div class="dish__img">
                            <img :src="require(`@/assets/images/discounts/${discount.img}`)" :alt="discount.title" />
                        </div>
                    </div>
                    <div class="dish__body">
                        <h4 class="dish__title">{{ discount.title }}</h4>
                        <div class="dish__text">
                            {{ discount.text }}
                        </div>
                        <button class="dish__more" @click="discount.id === 1 ? (popupLogin = true) : routerHrefLink()">
                            {{ discount.button }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <login-popup v-if="popupLogin" @click="popupLogin = !popupLogin" @close="popupLogin = false" @auth="userAuth" />
</template>

<script>
import LoginPopup from "./LoginPopup.vue";
import axios from "axios";
export default {
    components: { LoginPopup },
    name: "DiscountsMain",
    props: ["token"],
    data() {
        return {
            popupLogin: false,
            title: "Акции и новости",
            discounts: [
                {
                    id: 1,
                    title: `Кешбэк 5% с каждого заказа `,
                    text: `Участвуйте в программе лояльности\n сети ресторанов ZAMAN`,
                    img: "percent.png",
                    button: "Зарегистрироваться",
                },
                {
                    id: 2,
                    title: "Дарим 100 бонусов",
                    text: `Дарим 100 приветственных бонусов,\n чтобы попробовать блюда\n ресторана`,
                    img: "telegram.png",
                    button: "Подписаться",
                },
            ],
            telegram: "https://t.me/Zaman_tbtbot?start=link_pL98tdmlXj",
        };
    },
    methods: {
        routerHrefLink() {
            window.open(this.telegram, "_blank");
        },
        async userAuth(token) {
            try {
                const response = await axios.get("https://admin.tcode.online/api/projects/zaman/profile/", {
                    headers: {
                        Authorization: "Token " + token,
                    },
                });
                console.log(response.data);
                localStorage.setItem("token", token);
                localStorage.setItem("username", response.data.username);
                localStorage.setItem("name", response.data.first_name);
                this.$emit("authData", response.data);
                this.popupLogin = false;
            } catch (err) {
                // Если есть ошибка
                alert(err);
            }
        },
    },
};
</script>

<style lang="scss">
.discounts {
    background-color: #fafafa;
    padding: 60px 0;
    @media (max-width: 768px) {
        padding: 50px 15px;
    }
    .title {
        font-family: "Montserrat", sans-serif;
        color: #2a374b;
        line-height: clamp(12px, 5vw, 46.8px);
        font-size: clamp(16px, 5vw, 40px);
        text-align: center;
        font-weight: 700;
    }
    .dishes {
        display: flex;
        justify-content: center;
        @media (max-width: 768px) {
            flex-direction: column;
            margin-top: 20px;
        }
    }
    .dish {
        margin: 35px 5px 0px;
        max-width: 432px;
        height: auto;
        border-radius: 8px;
        background: #fff;
        box-shadow: 2px 2px 10px 0px rgb(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        @media (max-width: 768px) {
            margin: 20px auto 0;
            max-width: unset;
            &__title {
                font-size: 18px;
            }
        }
        &__title {
            font-size: 24px;
            line-height: 36px;
            color: #2a374b;
            font-weight: 600;
            @media (max-width: 768px) {
                font-size: 16px;
                line-height: 28px;
            }
        }
        &__img {
            height: 255px;
            overflow: hidden;
            border-radius: 8px 8px 0 0;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            @media (max-width: 768px) {
                height: 184px;
            }
        }

        &__text {
            font-size: 18px;
            line-height: 28px;
            color: #5a5a5a;
            margin-top: 10px;
            white-space: pre-line;
            flex: 1 1 auto;
            @media (max-width: 768px) {
                font-size: 12px;
                line-height: 16px;
                margin-top: 5px;
            }
        }

        &__more {
            font-size: 24px;
            line-height: 36px;
            color: #2a374b;
            width: 100%;
            height: 80px;
            border-radius: 50px;
            border: none;
            background: #e5eefa;
            margin-top: 20px;
            font-weight: 600;
            &:hover,
            &:focus {
                background: #cce0f9;
                transition: 0.2s ease 0.2s;
            }
            @media (max-width: 768px) {
                height: 64px;
                font-size: 16px;
                line-height: 24px;
            }
        }
        &__body {
            display: flex;
            flex-direction: column;
            padding: 20px 30px 30px;
            flex: 1 1 auto;
            @media (max-width: 768px) {
                padding: 15px 15px 20px;
            }
        }
    }
}
</style>
