<template>
    <header class="header">
        <div class="header__wrapper">
            <div class="flex-wrapper">
                <app-logo />
                <div class="halal">
                    <img :src="require(`@/assets/images/new-halyal.svg`)" alt="halal" />
                </div>
            </div>
            <!-- <router-link to="/nikahi" class="link-page">Никахи</router-link> -->
            <div class="flex-wrapper">
                <div class="mobile-tel">
                    <a href="tel:+79273546263" onclick="ym(95816218,'reachGoal','phone'); return true;">
                        <img src="@/assets/images/Telegram_black.png" alt="phone" />
                    </a>
                </div>
                <div class="header__body">
                    <ul class="header__menu" :style="$route.path === '/account' ? 'visibility: hidden' : false">
                        <li><a href="/#menu">Меню</a></li>
                        <li><a href="/#photos">Интерьер</a></li>
                        <li><router-link to="/nikahi">Никахи</router-link></li>
                        <li><a href="/#contacts">Контакты</a></li>
                        <li><router-link to="/loyalty-program">Программа лояльности</router-link></li>
                        <!-- <li><a href="/#photos">Фото</a></li> -->
                        <li><a href="#" @click="openPopupFeedback()">Отзывы</a></li>
                    </ul>
                    <div class="header__login">
                        <!-- <a
                            href="tel:+79273546263"
                            class="header__tel"
                            onclick="ym(95816218,'reachGoal','phone'); return true;"
                            >+7 927 354 62 63</a
                        > -->
                        <router-link v-if="token" to="/account" class="header__account">
                            <img src="../assets/images/svg/qr_logo.svg" alt="account" />
                            <span>Личный кабинет</span>
                        </router-link>
                        <button v-else class="header__account" @click="popupLogin = true">
                            <img src="../assets/images/svg/qr_logo.svg" alt="account" />
                            <span>Личный кабинет</span>
                        </button>
                    </div>
                </div>
                <nav class="nav" v-if="$route.path !== '/account'" @click="toggleMenu">
                    <button class="nav__burger burger">
                        <span class="burger__line burger__line--1"></span>
                        <span class="burger__line burger__line--2"></span>
                        <span class="burger__line burger__line--3"></span>
                    </button>
                    <div class="nav__wrapper" :class="{ 'nav__wrapper--open': isVisible }">
                        <div class="nav__header">
                            <button class="nav__burger">
                                <svg
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M21 1L1 21M21 21L1 1"
                                        stroke="#B0B0B0"
                                        stroke-width="2"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </button>
                            <!-- <a href="tel:+79273546263" class="nav__phone nav__link">+7 927 354 62 63</a> -->
                        </div>
                        <ul class="nav__menu menu">
                            <li class="menu__item" v-for="item in items" :key="item.id">
                                <template v-if="item.name !== 'Отзывы'">
                                    <a :href="item.to" class="nav__link">{{ item.name }}</a>
                                </template>
                                <template v-else>
                                    <span class="nav__link" @click="openPopupFeedback()">{{ item.name }}</span>
                                </template>
                            </li>
                        </ul>
                        <div class="menu__ornament-block">
                            <div class="menu__ornament">
                                <img src="../assets/images/ornament.png" alt="ornament" />
                            </div>
                        </div>
                        <div class="menu__login menu">
                            <!-- <a
                                href="tel:+79273546263"
                                class="menu__tel"
                                onclick="ym(95816218,'reachGoal','phone'); return true;"
                                >+7 927 354 62 63</a
                            > -->
                            <router-link v-if="token" to="/account" class="menu__account">
                                <img src="../assets/images/svg/qr_logo.svg" alt="account" />
                                <span>Личный кабинет</span>
                            </router-link>
                            <button v-else class="menu__account" @click="popupLogin = true">
                                <img src="../assets/images/svg/qr_logo.svg" alt="account" />
                                <span>Личный кабинет</span>
                            </button>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </header>
    <login-popup v-if="popupLogin" @click="popupLogin = !popupLogin" @close="hidePopup" @auth="userAuth" />
    <certif-popup v-if="popupCertif" @click="popupCertif = !popupCertif" />
    <feedback-popup v-if="isPopupFeedback" @close="isPopupFeedback = false" />
    <reserve-popup ref="reserve_popup" />
</template>

<script>
import AppLogo from "./AppLogo.vue";
import CertifPopup from "./CertifPopup.vue";
import LoginPopup from "./LoginPopup.vue";
import axios from "axios";
import FeedbackPopup from "./FeedbackPopup.vue";
import ReservePopup from "./ReservePopup.vue";

export default {
    components: { AppLogo, LoginPopup, CertifPopup, FeedbackPopup, ReservePopup },
    name: "app-header",
    data() {
        return {
            token: localStorage.getItem("token"),
            popupLogin: false,
            popupCertif: false,
            isVisible: false,
            isPopupFeedback: false,
            items: [
                { id: 1, to: "/#menu", name: "Меню" },
                { id: 2, to: "/#photos", name: "Интерьер" },
                { id: 3, to: "/nikahi", name: "Никахи" },
                { id: 4, to: "/#contacts", name: "Контакты" },
                { id: 5, to: "/loyalty-program", name: "Программа лояльности" },
                { id: 6, to: "#", name: "Отзывы" },
            ],
        };
    },
    props: {
        login: {
            type: String,
            default: "Личный кабинет",
        },
    },
    emits: {
        authData: null,
    },
    methods: {
        openPopupFeedback() {
            this.isPopupFeedback = true;
            this.isVisible = false;
        },
        hidePopup() {
            this.popupLogin = false;
        },
        toggleMenu() {
            this.isVisible = !this.isVisible;
        },
        async userAuth(token) {
            try {
                const response = await axios.get("https://admin.tcode.online/api/projects/zaman/profile/", {
                    headers: {
                        Authorization: "Token " + token,
                    },
                });
                console.log(response.data);
                localStorage.setItem("token", token);
                localStorage.setItem("username", response.data.username);
                localStorage.setItem("name", response.data.first_name);
                this.$emit("authData", response.data);
                this.popupLogin = false;
            } catch (err) {
                // Если есть ошибка
                alert(err);
            }
        },
    },
};
</script>

<style lang="scss">
.flex-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: max-content;

    &:first-child {
        gap: 16px;
    }
    &:last-child {
        gap: 20px;
    }
}
.header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    background-color: rgba(42, 55, 75, 0.17);
    backdrop-filter: blur(18px);
    height: 100px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    z-index: 10;
    width: 100%;
    padding: 0 2rem;
    @media (max-width: 768px) {
        padding: 0 !important;
    }
    &__wrapper {
        padding: 18px 0;
    }
    .mobile-tel {
        width: 28px;
        height: 28px;
        display: none;
        a {
            color: #fff;
            line-height: 20px;
            font-size: 14px;
            text-decoration: none;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    @media (max-width: 1100px) {
        width: 100%;
    }
    @media (max-width: 767px) {
        height: 64px;
        padding: 0 1rem;
        width: 100%;
        .mobile-tel {
            display: block;
        }
    }
    &__body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1 1 auto;
        @media (max-width: 767px) {
            display: none;
        }
        @media (max-width: 360px) {
            justify-content: flex-end;
        }
    }
    &__menu {
        display: flex;
        align-items: center;
        margin: 0;
        flex-wrap: wrap;
        @media (max-width: 360px) {
            display: none;
        }
        li {
            list-style: none;
            margin-left: 2.3rem;
            flex: 0 0 auto;
            @media (max-width: 1120px) {
                margin-left: 1rem;
                padding: 5px 0;
            }
        }
        a {
            color: #fff;
            line-height: clamp(8px, 5vw, 27.28px);
            font-size: clamp(8px, 5vw, 20px);
            text-decoration: none;
            &:hover,
            &:focus {
                color: #ccc;
                transition: 0.2s ease 0.2s;
            }
            @media (max-width: 800px) {
                display: none !important;
            }
        }
    }
    &__login {
        display: flex;
        align-items: center;
        @media (max-width: 991px) {
            margin-top: 1rem;
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
        @media (max-width: 767px) {
            display: none;
        }
    }
    &__account {
        font-size: 20px;
        font-weight: 400;
        line-height: 27.28px;
        text-align: center;
        color: #fff;
        margin-left: 35px;
        display: flex;
        align-items: center;
        background: none;
        border: none;
        cursor: pointer;
        flex: 0 0 auto;
        &:hover,
        &:focus {
            color: #ccc;
            text-shadow: 2px 2px 10px #000;
            transition: 0.2s ease 0.2s;
        }
        img {
            margin-right: 10px;
        }
    }
    &__tel {
        color: #fff;
        line-height: clamp(8px, 5vw, 25.2px);
        font-size: clamp(8px, 5vw, 18px);
        text-decoration: none;
        flex: 0 0 auto;
        margin-left: 20px;
        &:hover,
        &:focus {
            color: #ccc;
            transition: 0.2s ease 0.2s;
        }
        @media (max-width: 767px) {
            display: none;
        }
    }
    /* .header__wrapper */
    .header__wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        @media (max-width: 768px) {
            padding: 0 20px;
        }
    }
}
.burger {
    flex-direction: column;
    align-items: end;
    row-gap: 7px;
}
.burger__line {
    width: 20px;
    height: 1px;
    background-color: #fff;
    border-radius: 5px;
}
.burger__line--2 {
    width: 9.57px;
}
.burger__line--3 {
    width: 16.52px;
}
.halal {
    width: 40px;
    height: 40px;
    @media (max-width: 767px) {
        width: 30px;
        height: 30px;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.nav {
    display: none;
}
.nav__header {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.nav__burger {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    width: 20px;
    height: 20px;
}
.nav__link {
    text-decoration: none;
    color: #fff;
    width: 100%;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    font-family: "Montserrat", sans-serif;
}
.nav__wrapper {
    display: none;
    flex-direction: column;
    background-color: #2a374b;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
}
.nav__wrapper--open {
    display: flex;
    top: -15px;
    left: 0;
    z-index: 3;
}
.nav__menu.menu {
    margin-top: 30px;
}
.menu {
    list-style-type: none;
    padding: 0 20px;
    z-index: 3;
}
.menu__ornament-block {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    padding: 0 20px;
}
.menu__ornament {
    width: 62px;
    height: 12px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.menu__item {
    display: flex;
    align-items: center;
    margin-top: 30px;
}
.menu {
    &__tel {
        height: 40px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f5f5f5;
        text-decoration: none;
        color: #373737;
    }
    &__account {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0 auto;
        border: none;
        background: none;
        color: #e5eefa;
        text-decoration: none;
        font-weight: 600;
        font-family: "Montserrat", sans-serif;
        img {
            margin-right: 10px;
            width: 20px;
        }
    }
    &__login {
        margin-top: 35px;
    }
}
.link-page {
    display: none;
    color: #fff;
    text-decoration: none;
}
@media (max-width: 767px) {
    .nav,
    .link-page {
        display: block;
    }
    .nav__phone {
        display: none;
    }
}
</style>
