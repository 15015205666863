<template>
    <div class="banquet-hall">
        <div class="banquet-hall__container center-wrapper">
            <div class="banquet-hall__body">
                <div class="banquet-hall__TnF">
                    <!-- <div class="banquet-hall__text"> -->
                    <h2 class="banquet-hall__title">Банкетный зал<br />для вашего никаха</h2>
                    <ul class="banquet-hall__list">
                        <li>
                            <img class="list-mark" src="../assets/images/list-mark-orange.svg" />
                            Национальная татарская кухня с сертификатом «Халяль»
                        </li>
                        <li>
                            <img class="list-mark" src="../assets/images/list-mark-orange.svg" />
                            Зал вмещает до 100 гостей
                        </li>
                        <li>
                            <img class="list-mark" src="../assets/images/list-mark-orange.svg" />
                            Молельная комната
                        </li>
                        <li>
                            <img class="list-mark" src="../assets/images/list-mark-orange.svg" />
                            Шоу с чак-чаком, шоу с гусем, кумыс фуршет
                        </li>
                    </ul>
                    <!-- </div> -->
                    <!-- <div class="banquet-hall__form"> -->
                    <form @submit.prevent>
                        <div class="input-blocks">
                            <div class="input-block">
                                <label for="name">Ваше имя</label>
                                <input type="text" name="name" placeholder="Имя" id="name" v-model.trim="name" />
                                <button type="submit" class="btn-send" @click="validForm">Оставить заявку</button>
                            </div>
                            <div class="input-block">
                                <label for="tel">Номер телефона</label>
                                <input
                                    type="text"
                                    name="tel"
                                    v-mask="'+7 ### ### ## ##'"
                                    placeholder="+7 000 000 00 00"
                                    id="tel"
                                    v-model.trim="phone"
                                />
                                <button type="submit" class="btn-send more-info" @click="$router.push('/nikahi')">
                                    Узнать подробнее
                                </button>
                            </div>
                        </div>
                        <div class="input-block-flex">
                            <p class="agree">
                                Указывая номер телефона, вы принимаете<br />
                                <a href="/Политика_конфиденциальности.pdf" target="_blank"
                                    >Политику обработки персональных данных</a
                                >
                            </p>
                        </div>
                    </form>
                    <div class="valid" :style="`color:${color}`">
                        {{ valid }}
                    </div>
                    <!-- </div> -->
                </div>
                <div class="mobile-comp">
                    <h2 class="banquet-hall__title">Банкетный зал<br />для вашего никаха</h2>
                    <ul class="banquet-hall__list">
                        <li>
                            <img class="list-mark" src="../assets/images/list-mark-orange.svg" />
                            Национальная татарская кухня с сертификатом «Халяль»
                        </li>
                        <li>
                            <img class="list-mark" src="../assets/images/list-mark-orange.svg" />
                            Зал вмещает до 100 гостей
                        </li>
                        <li>
                            <img class="list-mark" src="../assets/images/list-mark-orange.svg" />
                            Молельная комната
                        </li>
                        <li>
                            <img class="list-mark" src="../assets/images/list-mark-orange.svg" />
                            Шоу с чак-чаком, шоу с гусем, кумыс фуршет
                        </li>
                    </ul>
                </div>
                <div class="banquet-hall__photos">
                    <swiper
                        :modules="modules"
                        :slides-per-view="1"
                        :space-between="0"
                        :pagination="{ clickable: true }"
                        @swiper="onSwiper"
                        @slideChange="onSlideChange"
                    >
                        <swiper-slide v-for="(item, index) in items" :key="item.id" @click="slideToIndex(index)">
                            <img :src="require(`@/assets/images/banket-hall/${item.img}`)" alt="slide" />
                        </swiper-slide>
                    </swiper>
                </div>
                <form class="mobile-comp" @submit.prevent>
                    <div class="input-block">
                        <label for="name">Ваше имя</label>
                        <input type="text" name="name" placeholder="Имя" id="name" v-model.trim="name" />
                    </div>
                    <div class="input-block">
                        <label for="tel">Номер телефона</label>
                        <input
                            type="text"
                            name="tel"
                            v-mask="'+7 ### ### ## ##'"
                            placeholder="+7 000 000 00 00"
                            id="tel"
                            v-model.trim="phone"
                        />
                        <button type="submit" class="btn-send" @click="validForm">Оставить заявку</button>
                        <button type="submit" class="btn-send more-info" @click="$router.push('/nikahi')">
                            Узнать подробнее
                        </button>
                    </div>
                    <div class="valid" :style="`color:${color}`">
                        {{ valid }}
                    </div>
                    <div class="input-block-flex">
                        <p class="agree">
                            Указывая номер телефона, вы принимаете<br />
                            <a href="/Политика_конфиденциальности.pdf" target="_blank"
                                >Политику обработки персональных данных</a
                            >
                        </p>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
export default {
    name: "main-banquet-hall",
    components: { Swiper, SwiperSlide },
    setup() {
        return {
            modules: [Pagination, Autoplay],
        };
    },
    data() {
        return {
            name: "",
            phone: "",
            valid: "",
            color: "red",
            title: `Банкетный зал\nдля вашего никаха`,
            items: [
                { id: 1, img: "6.png" },
                { id: 2, img: "2.jpg" },
                { id: 3, img: "IMG_6009.jpg" },
                { id: 4, img: "1.jpg" },
                { id: 5, img: "5.jpg" },
            ],
            index: 1,
        };
    },
    methods: {
        onSwiper(swiper) {
            this.swiper = swiper;
        },
        onSlideChange(swiper) {
            this.swiper = swiper;
            this.index = this.swiper.activeIndex + 1;
        },
        slideToIndex() {
            this.swiper.slideTo(this.index++);
            if (this.index >= this.items.length) {
                this.index = 0;
            }
        },
        validForm() {
            this.color = "red";
            if (this.name === "") {
                this.valid = "Заполните поле Имя";
            } else if (this.phone === "") {
                this.valid = "Укажите телефон";
            } else if (this.name.length <= 1) {
                this.valid = "Слишком короткое имя";
            } else if (this.phone.length <= 15) {
                this.valid = "Телефон должен состоять из 11 цифр!";
            } else {
                this.orderPost();
                this.name = "";
                this.phone = "";
                this.color = "";
                this.valid = "Спасибо. Заявка успешно отправлена!";
            }
        },
        orderPost() {
            axios
                .post(
                    "https://admin.tcode.online/api/projects/zaman/site-requests/",
                    {
                        page: "Никах | Заман",
                        purpose: "Заявка на никах",
                        name: this.name,
                        phone: this.phone,
                        shop_id: 8,
                        shop_name: "Заман",
                    },
                    {
                        headers: {
                            // remove headers
                        },
                    }
                )
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error.response);
                    this.valid = "Некорректно введен телефон";
                });
        },
    },
    mounted() {
        window.innerWidth > 767
            ? (this.title = `Банкетный зал для\nвашего торжества`)
            : (this.title = `Банкетный зал\nдля вашего торжества`);
    },
};
</script>

<style lang="scss">
.mobile-comp {
    margin: 0 !important;
    width: 100vw;
    padding: 0 24px !important;

    @media (min-width: 767px) {
        display: none !important;
    }
}
form.mobile-comp {
    display: flex;
    flex-direction: column;
    gap: 36px;
}
form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.list-mark {
    width: 32px;
}
.banquet-hall {
    background-color: #f6ece9;
    padding-top: 80px;
    padding-bottom: 60px;
    .valid {
        position: relative;
        top: 1rem;
        text-align: center;
        @media (max-width: 767px) {
            font-size: 12px;
        }
    }
    @media (max-width: 991px) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    &__body {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        @media (max-width: 1200px) {
            flex-direction: column;
            align-items: center;
        }
    }
    &__TnF {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: max-content;
        form {
            flex-direction: column;
        }
        @media (max-width: 767px) {
            display: none;
        }
    }
    &__title {
        // font-family: "Century Gothic";
        font-size: clamp(18px, 5vw, 48px);
        line-height: clamp(26px, 5vw, 64px);
        font-weight: 600;
        white-space: pre;
        margin: 0;
        @media (max-width: 767px) {
            font-size: 26px;
            line-height: 32px;
        }
    }
    &__list {
        font-size: 18px;
        line-height: 28px;
        list-style: none;
        margin: 0;
        padding: 0;
        @media (max-width: 991px) {
            margin-top: 2rem;
            font-size: 16px;
        }
        @media (max-width: 767px) {
            font-size: 12px;
            line-height: 14px;
            margin-top: 1.5rem;
            font-size: 10px;
        }

        li {
            margin-top: 16px;
            display: flex;
            flex-direction: row;
            align-items: center;
            align-items: flex-end;
            gap: 14px;

            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 24px;
                align-items: flex-start;
            }
        }
    }
    &__photos {
        display: flex;
        justify-content: space-between;
        width: 490px;
        height: 632px;
        .swiper {
            .swiper-slide {
                border-radius: 16px;
                overflow: hidden;
                padding-bottom: 40px;
                cursor: pointer;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: inherit;
                }
            }
            .swiper-pagination {
                display: flex;
                justify-content: center;
                align-items: center;
                .swiper-pagination-bullet {
                    width: 12px;
                    height: 12px;
                    margin: 0 5px;
                    background: #2a374b;
                    opacity: 0.15;
                    &.swiper-pagination-bullet-active {
                        width: 16px;
                        height: 16px;
                        opacity: 1;
                    }
                }
            }
        }
        @media (max-width: 1200px) {
            margin-top: 30px;
            height: max-content;
        }
        @media (max-width: 767px) {
            width: 100%;
            margin: 30px auto 50px;
        }
    }
    &__small {
        width: 48%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media (max-width: 480px) {
            height: 75%;
        }
        .photo {
            width: 48%;
            height: 395px;
            margin-bottom: 20px;
            @media (max-width: 1200px) {
                height: 300px;
            }
            @media (max-width: 991px) {
                height: 250px;
            }
            @media (max-width: 767px) {
                height: auto;
                margin-bottom: 5px;
            }
            @media (max-width: 480px) {
                height: 48%;
            }
        }
    }

    &__big {
        width: 50%;
        @media (max-width: 480px) {
            height: 75%;
        }
        .photo {
            margin-bottom: 15px;
            @media (max-width: 767px) {
                height: 98%;
                margin-bottom: 10px;
            }
        }
    }
    .input-blocks {
        display: flex;
        margin-top: 20px;
        .input-block {
            margin-right: 35px;
        }
    }
    .input-block-flex {
        display: flex;
        align-items: center;
        margin-top: 35px;
        @media (max-width: 991px) {
            flex-direction: column;
            align-items: flex-start;
        }
        @media (max-width: 767px) {
            margin-top: 0;

            p {
                margin: 0;
            }
        }
    }
    .input-block {
        width: 296px;
        display: flex;
        flex-direction: column;
        input {
            margin-top: 20px;
        }
        button {
            margin-top: 30px;
        }
        @media (max-width: 767px) {
            margin-top: 10px;
            width: 100%;
        }
    }
    .input-block label,
    .input-block input {
        @media (max-width: 767px) {
            // font-size: 12px;
            // line-height: 35px;
        }
    }
    .input-block input {
        padding: 0 0 20px 0;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #2a374b;
        height: max-content;
        outline: none;
        background: none;

        @media (max-width: 767px) {
            // height: 32px;
            // border-radius: 4px;
            padding: 0 10px;
            padding-bottom: 16px;
            font-size: 16px;
        }
    }
    .input-block label {
        margin: 0;
        @media (max-width: 767px) {
            margin-bottom: 5px;
            font-size: 16px;
        }
    }
    .btn-send {
        min-width: 231px;
        height: 80px;
        border-radius: 40px;
        background: #2a374b;
        font-family: "Montserrat", sans-serif;
        color: #fff;
        border: none;
        cursor: pointer;
        line-height: clamp(13.64px, 5vw, 30.01px);
        font-size: clamp(10px, 5vw, 22px);
        &.more-info {
            background: none;
            color: #2a374b;
            border: 1px solid #2a374b;
        }

        @media (max-width: 767px) {
            min-width: 150px;
            height: 64px;
            border-radius: 40px;
            font-size: 16px;
            line-height: 20px;
        }
        @media (max-width: 480px) {
            min-width: 108px;
            // height: 24px;
            // font-size: 10px;
            line-height: 13.64px;
        }
    }
    .agree {
        color: #cacaca;
        line-height: 14px;
        font-size: 14px;
        // margin-left: 20px;
        a {
            text-decoration: underline;
            color: inherit;
        }
        @media (max-width: 991px) {
            margin-top: 20px;
            margin-left: 0;
        }
        @media (max-width: 767px) {
            font-size: 12px;
        }
        @media (max-width: 480px) {
            font-size: 10px;
        }
    }
    .photo {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 20px;
            @media (max-width: 767px) {
                border-radius: 10px;
            }
            @media (max-width: 480px) {
                border-radius: 5px;
            }
        }
    }
}
.ai {
    position: relative;
    overflow: hidden;
    padding: 0 0 50% 0;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }
}
</style>
