<template>
    <div class="login-popup">
        <div class="login-popup__popup" @click.stop>
            <div class="login-popup__close" @click="closeModal">
                <img src="../assets/images/svg/close.svg" alt="close" />
            </div>
            <div class="login-popup__body">
                <h2 class="login-popup__title">{{ title }}</h2>
                <p class="login-popup__subtitle">{{ subtitle }}</p>
                <template v-if="verInputCode">
                    <div class="login-popup__ver-code">
                        <input type="tel" name="code" v-model="verCode" maxlength="4" />
                    </div>
                    <div class="login-popup__buttons">
                        <div class="login-popup__repeat">Повторить SMS</div>
                        <div class="login-popup__edit">Изменить данные</div>
                    </div>
                </template>
                <template v-else>
                    <div class="login-popup__input">
                        <input
                            type="text"
                            name="tel"
                            v-mask="'+7 ### ### ## ##'"
                            :placeholder="placeholder"
                            v-model.trim="phone"
                        />
                    </div>
                    <div class="login-popup__agree">
                        {{ agree }}
                        <a href="/Политика_конфиденциальности.pdf" target="_blank">{{ conf }}</a>
                    </div>
                    <button class="login-popup__btn" @click="setNumberPhone()">Продолжить</button>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
export default {
    data() {
        return {
            title: "Войдите в личный кабинет",
            subtitle: "Укажите номер телефона, чтобы\n войти в личный кабинет",
            placeholder: "+7 000 000 00 00",
            agree: "Указывая номер телефона, вы принимаете",
            conf: "Политику обработки персональных данных",
            phone: "",
            id_project: 2,
            verCode: "",
            verInputCode: false,
            verCodeError: false,
        };
    },
    methods: {
        setNumberPhone() {
            if (this.phone !== "") {
                axios
                    .post("https://admin.tcode.online/api/projects/zaman/auth/phone/", {
                        phone: this.phone,
                        project: this.id_project,
                    })
                    .then((response) => {
                        console.log(response);
                        this.verInputCode = true;
                        this.title = `Введите код`;
                        this.subtitle = `Мы отправили его на номер\n${this.phone}`;
                    })
                    .catch((error) => {
                        console.log(error.response);
                    });
            }
        },

        validateCode() {
            const phone = this.phone.replace(/\s+/g, "").slice(1);

            axios
                .post("https://admin.tcode.online/api/projects/zaman/auth/verification/", {
                    phone: phone,
                    code: this.verCode,
                })
                .then((response) => {
                    this.$emit("auth", response.data.token);
                    this.verCodeError = false;
                    setTimeout(() => {
                        window.location.replace("/account");
                    }, 2000);
                })
                .catch((error) => {
                    console.log(error.response);
                    this.verCodeError = true;
                });
        },

        closeModal() {
            this.verInputCode = false;
            this.$emit("close");
        },
    },
    watch: {
        verCode(newValue) {
            if (newValue.length === 4) {
                this.validateCode();
            }
        },
    },
};
</script>

<style lang="scss">
.login-popup {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    border: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    &__popup {
        width: 100%;
        max-width: 512px;
        height: 400px;
        background-color: #fff;
        border-radius: 10px;
        position: relative;
    }
    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
        width: 20px;
        height: 20px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        &:hover,
        &:focus {
            opacity: 0.5;
            transition: 0.2s ease 0.2s;
        }
    }
    &__body {
        width: 100%;
        max-width: 430px;
        text-align: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    &__title {
        font-size: 20px;
        font-family: "MontserratBold";
        line-height: 24px;
        text-align: center;
        color: #2a374b;
    }
    &__subtitle {
        font-size: 14px;
        font-family: "MontserratBold";
        line-height: 24px;
        text-align: center;
        color: #0d0d0d;
        margin-top: 1.5rem;
        white-space: pre;
    }
    &__input {
        border: 1px solid #b0b0b0;
        border-radius: 10px;
        overflow: hidden;
        width: 322px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        input {
            width: 100%;
            font-size: 14px;
            font-family: "MontserratBold";
            line-height: 24px;
            color: #0d0d0d;
            background: none;
            padding: 0 1rem;
            border: none;
            outline: none;
            &::placeholder {
                color: #b0b0b0;
            }
        }
    }
    &__btn {
        width: 240px;
        height: 56px;
        background-color: #2a374b;
        border-radius: 100px;
        margin-top: 30px;
        font-family: "MontserratBold";
        font-size: 16px;
        line-height: 28px;
        color: #fff;
        text-align: center;
        &:hover,
        &:focus {
            opacity: 0.9;
            transition: 0.2s ease 0.2s;
        }
    }
    &__agree {
        font-size: 8px;
        font-family: "MontserratBold";
        line-height: 14px;
        color: #b0b0b0;
        margin-top: 1rem;
        text-align: center;
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        a {
            color: inherit;
            text-decoration: underline;
            &:hover,
            &:focus {
                text-decoration: none;
                transition: 0.2s ease 0.2s;
            }
        }
    }
    &__ver-code {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        input {
            border: none;
            border-bottom: 1px solid #373737;
            font-family: "MontserratBold";
            font-size: clamp(16px, 5vw, 22px);
            line-height: clamp(21.82px, 5vw, 30.01px);
            color: #373737;
            text-align: center;
            width: 100%;
            max-width: 230px;
            outline: none;
        }
    }
    &__buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        width: 100%;
    }
    &__repeat,
    &__edit {
        font-size: 20px;
        line-height: 27.28px;
        color: #d07f67;
        cursor: pointer;
        text-align: center;
        &:hover,
        &:focus {
            color: #cf6545;
            transition: 0.2s ease 0.2s;
        }
    }
}
</style>
