<template>
  <div>
    <router-link to="/" class="logo">
      <img class="logo__img" src="../assets/images/new-logo.svg" alt="logo" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: "app-logo",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.logo__img {
  // width: 138.98px;
  height: 40px;

  @media (max-width: 767px) {
    width: 72.51px;
    height: 30.49px;
    // margin-top: 15px;
  }
}
</style>