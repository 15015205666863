<template>
    <main>
        <main-page-banner />
        <!-- <main-set-menu /> -->
        <!-- <main-set-menu-winter /> -->
        <!-- <main-set-menu-iftar /> -->
        <div class="advantages">
            <div class="center-wrapper">
                <div class="ornament">
                    <img class="ornament-img" src="../assets/images/ornament-vector.svg" />
                    <div class="line"></div>
                </div>
                <div class="adv-sect">
                    <div class="adv-sect-block" v-for="el in advantages_data" :key="el.id">
                        <div class="img-block">
                            <img :src="el.vector" />
                        </div>
                        <p>{{ el.title }}</p>
                    </div>
                </div>
                <div class="ornament__right">
                    <div class="line"></div>
                    <img class="ornament-img" src="../assets/images/ornament-vector.svg" />
                </div>
            </div>
        </div>
        <main-page-catalog />
        <!-- <iftar-sadaka /> -->
        <AboutChief />
        <MainPageInterior />
        <!-- <main-page-photos /> -->
        <!-- <MainPageCatalog /> -->
        <!-- <main-page-stories /> -->
        <main-page-reservation />
        <main-banquet-hall />
        <main-page-discounts />
        <MainPageLocation />
    </main>
</template>

<script>
import AboutChief from "@/components/AboutChief.vue";
import MainPageBanner from "../components/MainPageBanner.vue";
import MainPageReservation from "../components/MainPageReservation.vue";
import MainBanquetHall from "@/components/MainBanquetHall.vue";
import MainPageDiscounts from "@/components/MainPageDiscounts.vue";
import halal_img from "../assets/images/halal-vector.svg";
import logo_img from "../assets/images/logo-vector.svg";
import city_img from "../assets/images/city-vector.svg";
import rings_img from "../assets/images/rings-vector.svg";
import MainPageInterior from "@/components/MainPageInterior.vue";
import MainPageLocation from "@/components/MainPageLocation.vue";
import MainPageCatalog from "@/components/MainPageCatalog.vue";
//import MainSetMenuIftar from "@/components/MainSetMenuIftar.vue";
//import IftarSadaka from "@/components/IftarSadaka.vue";

export default {
    components: {
        MainPageBanner,
        MainPageInterior,
        MainPageReservation,
        MainBanquetHall,
        MainPageDiscounts,
        AboutChief,
        MainPageLocation,
        MainPageCatalog,
        //MainSetMenuIftar,
        //IftarSadaka,
    },
    data() {
        return {
            advantages_data: [
                {
                    id: 1,
                    vector: halal_img,
                    title: "Халяль меню",
                },
                {
                    id: 2,
                    vector: logo_img,
                    title: "Татарское гостеприимство",
                },
                {
                    id: 3,
                    vector: city_img,
                    title: "Терраса с видом на Уфу",
                },
                {
                    id: 4,
                    vector: rings_img,
                    title: "Проведение никахов",
                },
            ],
        };
    },
    name: "main-zaman",
};
</script>
<style lang="scss">
.advantages {
    background-color: rgba(229, 238, 250, 1);
    padding: 80px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 108px;
    .center-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.ornament {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: max-content;
    height: max-content;
    gap: 0;

    @media (max-width: 767px) {
        display: none;
    }
    .ornament-img {
        // width: 50px;
        height: 18px;
    }
    .line {
        transform: translateX(-35px);
        height: 3px;
        width: 35px;
        background-color: #2a374b;
    }

    &__right {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: max-content;
        height: max-content;
        gap: 0;

        @media (max-width: 767px) {
            display: none;
        }
        .ornament-img {
            transform: rotate(180deg);
        }
        .line {
            transform: translateX(35px);
            height: 3px;
            width: 35px;
            background-color: #2a374b;
        }
    }
}
.adv-sect {
    display: flex;
    flex-direction: row;
    gap: 108px;

    @media (max-width: 767px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 48px;

        .adv-sect-block {
            row-gap: 24px;
            column-gap: 60px !important;

            // .img-block{
            //     width: 100px !important;
            //     height: 100px !important;
            // }

            p {
                font-size: 16px !important;
            }
        }
    }
    @media (max-width: 450px) {
        .adv-sect-block .img-block {
            width: 100px !important;
            height: 100px !important;

            img {
                height: 40px;
            }
        }
    }

    .adv-sect-block {
        display: flex;
        flex-direction: column;
        gap: 36px;
        width: min-content;

        .img-block {
            width: 172px;
            height: 172px;
            background-color: #fff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            // img{
            //     height: 50%;
            // }
        }
        p {
            font-size: 24px;
            font-family: "Montserrat", sans-serif;
            text-align: center;
        }
    }
}
</style>
