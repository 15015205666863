<template>
    <section class="about-chief">
        <div class="about-chief__info">
            <h1>О шефе</h1>
            <p>
                Как музыканты знают, какие ноты звучат друг с другом лучше, мы знаем, как сочетать продукты. Всё чаще
                люди приходят в рестораны ради шеф-поваров, чтобы «попробовать» их стиль. Знаете, этакий своеобразный
                концерт вкусов
            </p>
            <p>
                <span>Алексей Чернов — бренд-шеф ресторанов группы T-family.</span><br />
                С раннего детства проводил лето у дедушки с бабушкой в татарской деревне, где его часто угощали
                традиционными татарскими угощениями. Так зародилась любовь к татарской кухне, которую сегодня шеф
                успешно транслируют в трёх ресторанах: Тюбетей, TATAR и ZAMAN.
            </p>
            <ul>
                <li>
                    <img src="../assets/images/logo-vector.svg" />
                    Бренд-шеф группы компаний T-Family (ресторан Татар, Заман, Тюбетей)
                </li>
                <li>
                    <img src="../assets/images/logo-vector.svg" />
                    Член Гильдии шеф-поваров РТ
                </li>
                <li>
                    <img src="../assets/images/logo-vector.svg" />
                    Постоянный член жюри Регионального турнира молодых поваров имени Юнуса Ахметзянова
                </li>
                <li>
                    <img src="../assets/images/logo-vector.svg" />
                    Прошел обучение в Академии кулинарного искусства «Эксклюзив»
                </li>
                <li>
                    <img src="../assets/images/logo-vector.svg" />
                    Участник конкурса «Пир Поволжья», 2007 г., Серебро
                </li>
                <li>
                    <img src="../assets/images/logo-vector.svg" />
                    Участник конкурса «Пир Экспо Рациональ» 2018г., Бронза
                </li>
                <li>
                    <img src="../assets/images/logo-vector.svg" />
                    Участник конкурса имени Юнуса Ахметзнова 2016 г., Приз зрителей симпатий
                </li>
            </ul>
        </div>
        <div class="about-chief__photo">
            <img src="../assets/images/chief-foto.png" />
        </div>
    </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.about-chief {
    display: flex;
    flex-direction: row;
    padding: 80px 56px;
    background-color: #f6ece9;
    gap: 50px;

    &__photo {
        img {
            width: 521px;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h1 {
            margin: 0;
            font-size: 48px;
            font-family: "Montserrat", sans-serif;
            font-weight: 500;
            @media (max-width: 767px) {
                font-size: 26px;
            }
        }
        p {
            font-family: "Montserrat", sans-serif;
            font-size: 18px;
            line-height: 28px;
            margin: 0;
        }
        span {
            font-weight: 700;
        }
        ul {
            padding: 0;
            margin: 0;
            li {
                list-style-type: none;
                height: 16px;
                margin-bottom: 16px;
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 12px;

                &:last-child {
                    margin: 0;
                }

                img {
                    width: 16px;
                    margin-right: 16px;
                }
            }
        }
    }
}

@media (max-width: 1100px) {
    .about-chief {
        flex-direction: column;
        // align-items: center;

        &__info {
            gap: 25px;
        }
    }
}
@media (max-width: 767px) {
    .about-chief {
        padding: 64px 24px;

        &__photo {
            img {
                width: 100%;
            }
        }
    }
}
</style>
