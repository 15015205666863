<template>
    <div class="plug">
        <div class="plug__container">
            <div class="plug__logo">
                <img class="logo__img" src="../assets/images/plug-logo.svg" alt="logo" />
            </div>
            <div class="plug__body">
                <h1 class="plug__title">Сайт временно в разработке</h1>
                <div class="plug__text">
                    <p>
                        По всем подробностям обращайтесь<br />
                        по телефону +7 927 354 62 63
                    </p>
                </div>
                <div class="plug__btn-call">
                    <a href="tel:+79273546263">Позвонить</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "plug-block",
    created() {},
    data() {
        return {};
    },
    props: {},
    methods: {},
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz@6..12&display=swap");
@font-face {
    font-family: "Century Gothic";
    src: local("../assets/fonts/Century Gothic Regular"), local("../assets/fonts/Century-Gothic-Regular"),
        url("../assets/fonts/CenturyGothic.woff2") format("woff2"),
        url("../assets/fonts/CenturyGothic.woff") format("woff"),
        url("../assets/fonts/CenturyGothic.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
.plug {
    height: 100vh;
    background-color: #2a374b;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__logo {
        width: 138.98px;
        height: 58.9px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        @media (max-width: 360px) {
            width: 82.58px;
            height: 35px;
        }
    }
    &__body {
        text-align: center;
    }
    &__title {
        font-family: "Century Gothic";
        font-size: clamp(18px, 5vw, 55px);
        line-height: clamp(26px, 5vw, 67.43px);
        color: #fff;
    }
    &__text {
        font-family: "Century Gothic";
        font-size: 35px;
        line-height: clamp(18px, 5vw, 45px);
        color: #fff;
        @media (max-width: 1024px) {
            font-size: 20px;
        }
        @media (max-width: 360px) {
            font-size: 12px;
        }
    }
    &__btn-call {
        display: flex;
        justify-content: center;
        margin: 3rem auto 0;
        a {
            width: 232px;
            height: 60px;
            border-radius: 40px;
            background-color: #d07f67;
            font-family: "Montserrat", sans-serif;
            font-size: 22px;
            line-height: 30.01px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            &:hover,
            &:focus {
                background-color: #ce5733;
                transition: 0.2s ease 0.2s;
            }
            @media (max-width: 360px) {
                font-size: 12px;
                line-height: 16.37px;
                width: 119px;
                height: 32px;
            }
        }
    }
}
</style>
