<template>
    <div class="popup-up-sorting">
        <p class="popup-up-sorting__question">Какие показать сначала?</p>
        <div class="popup-up-sorting__list">
            <div class="popup-up-sorting__var">
                <input type="radio" name="sorting" checked id="sorting1" />
                <label for="sorting1">Доверюсь вам</label>
            </div>
            <div class="popup-up-sorting__var">
                <input type="radio" name="sorting" id="sorting2" />
                <label for="sorting2">Недорогие</label>
            </div>
            <div class="popup-up-sorting__var">
                <input type="radio" name="sorting" id="sorting3" />
                <label for="sorting3">Дорогие</label>
            </div>
            <div class="popup-up-sorting__var">
                <input type="radio" name="sorting" id="sorting4" />
                <label for="sorting4">С высоким рейтингом</label>
            </div>
            <div class="popup-up-sorting__var">
                <input type="radio" name="sorting" id="sorting5" />
                <label for="sorting5">Низкая колорийность</label>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.popup-up-sorting {
    width: 330px;
    height: 360px;
    background-color: #fff;
    border-radius: 20px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    right: 0;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    @media (max-width: 360px) {
        width: 184px;
        height: 176px;
        padding: 1rem;
    }
    &__question {
        font-family: "Montserrat", sans-serif;
        font-size: 20px;
        line-height: 27.28px;
        color: #000;
        @media (max-width: 360px) {
            font-size: 12px;
            line-height: 16.37px;
        }
    }
    &__list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 1.5rem 0 1rem;
        @media (max-width: 360px) {
            padding: 0.5rem 0 0;
        }
    }
    &__var {
        display: flex;
        align-items: center;
        input[type="radio"] {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }
        input[type="radio"] + label {
            display: flex;
            user-select: none;
            cursor: pointer;
            font-family: "Montserrat", sans-serif;
            font-weight: 300;
            font-size: 18px;
            line-height: 24.55px;
            color: #000;
            @media (max-width: 360px) {
                font-size: 10px;
                line-height: 13.64px;
            }
            &::before {
                content: "";
                width: 20px;
                height: 20px;
                box-sizing: border-box;
                border-radius: 50%;
                cursor: pointer;
                margin-right: 5px;
                background: #f5f5f5;
                box-shadow: inset 0 0 0px 2px #ececec;
                @media (max-width: 360px) {
                    width: 10px;
                    height: 10px;
                }
            }
        }
        input[type="radio"]:checked + label::before {
            background-repeat: no-repeat;
            background-position: center;
            box-shadow: inset 0 0 0px 5px #373737;
            @media (max-width: 360px) {
                box-shadow: inset 0 0 0px 2.5px #373737;
            }
        }
    }
}
</style>
