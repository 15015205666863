<template>
    <footer class="footer">
        <div class="footer__block">
            <div class="subNmenu">
                <div class="sub-block">
                    <h3 class="sub-block__text">Подпишись, чтобы быть в курсе новостей</h3>
                    <a href="https://vk.com/zaman_tbt" target="_blank">
                        <img src="../assets/images/VK-white.svg" />
                    </a>
                </div>
                <img class="ornam" src="../assets/images/ornament-long-vector.svg" />
                <div class="menu-block">
                    <ul>
                        <li>
                            <a href="#" @click="$refs.reserve_popup.openPopup">Забронировать стол</a>
                        </li>
                        <li>
                            <a href="/#menu">Меню</a>
                        </li>
                        <li>
                            <a href="/#photos">Интерьер</a>
                        </li>
                        <li>
                            <router-link to="/nikahi">Никахи</router-link>
                        </li>
                        <li>
                            <router-link v-if="token" to="/account">Личный кабинет</router-link>
                            <span v-else @click="popupLogin = true">Личный кабинет</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="footer-links">
                <a hef="#" class="footer-links__link mail-link">zaman.tbt@gmail.com</a>
                <a href="/Политика_конфиденциальности.pdf" class="footer-links__link">Политика конфиденциальности</a>
                <p class="footer-links__link">Все права защищены</p>
                <p class="footer-links__link">© Заман, 2024, ООО "ТЮБЕТЕЙ ЧИСТОПОЛЬСКАЯ"</p>
            </div>
        </div>
    </footer>
    <reserve-popup ref="reserve_popup" />
    <login-popup v-if="popupLogin" @click="popupLogin = !popupLogin" @close="hidePopup" @auth="userAuth" />
</template>

<script>
import ReservePopup from "./ReservePopup.vue";
// import AppLogo from "./AppLogo.vue";
import LoginPopup from "./LoginPopup.vue";
import axios from "axios";
export default {
    components: { ReservePopup, LoginPopup },
    // components: { AppLogo },
    name: "app-footer",
    data() {
        return {
            token: localStorage.getItem("token"),
            popupLogin: false,
        };
    },
    props: {
        login: {
            type: String,
            default: "Личный кабинет",
        },
    },
    emits: {
        authData: null,
    },
    methods: {
        hidePopup() {
            this.popupLogin = false;
        },
        async userAuth(token) {
            try {
                const response = await axios.get("https://admin.tcode.online/api/projects/zaman/profile/", {
                    headers: {
                        Authorization: "Token " + token,
                    },
                });
                console.log(response.data);
                localStorage.setItem("token", token);
                localStorage.setItem("username", response.data.username);
                localStorage.setItem("name", response.data.first_name);
                this.$emit("authData", response.data);
                this.popupLogin = false;
            } catch (err) {
                // Если есть ошибка
                alert(err);
            }
        },
    },
};
</script>

<style lang="scss">
.footer {
    background-color: #0d0d0d;
    padding: 80px 0;
    color: #fff;

    &__block {
        width: 720px;
        margin: 0 auto;

        @media (max-width: 767px) {
            width: 100vw;
        }
    }
}

.footer-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
        flex-direction: column;
        width: max-content;
        align-items: center;
        margin: 0 auto;
        margin-top: 50px;
    }

    &__link {
        color: #a9abb0;
        font-size: 8px;
        margin: 0;

        &.mail-link {
            font-size: 16px;
            color: #b0b0b0;
        }
    }

    a {
        text-decoration: none;
    }
}

.subNmenu {
    display: flex;
    flex-direction: row;
    gap: 150px;
    margin-bottom: 80px;
    // justify-content: flex-start;

    @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        gap: 0;
        width: 50%;
        margin: 0 auto;
        // justify-content: center;

        .ornam {
            height: 200px;
            transform: rotate(90deg);
            // width: 20px;
            margin: 0 auto;
            margin: 0;
        }
    }

    .sub-block {
        &__text {
            font-size: 18px;
            margin-bottom: 20px;
        }

        @media (max-width: 767px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
    }

    .menu-block ul {
        list-style: none;
        padding: 0;
        width: max-content;

        li {
            margin-bottom: 16px;

            @media (max-width: 767px) {
                text-align: center;
                margin: 0;
            }

            a {
                text-decoration: none;
                color: #fff;
                font-size: 16px;
                line-height: 24px;
            }

            span {
                cursor: pointer;
            }

            &:last-child {
                margin: 0;
            }
        }
    }
}
</style>
