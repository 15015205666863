<template>
    <!--<div class="under-construction">
    <img src="../assets/images/bg.jpg" alt="" />
  </div>-->
    <plug v-if="isPlug" />
    <template v-else>
        <app-header v-if="$route.name !== ('nikahi' && 'sadaka')" :login="login" />
        <router-view />
        <app-footer v-if="$route.name !== ('nikahi' && 'sadaka')" :login="login" />
    </template>
</template>

<script>
import AppFooter from "./components/AppFooter.vue";
import AppHeader from "./components/AppHeader.vue";
import Plug from "./components/Plug.vue";
export default {
    components: { AppHeader, AppFooter, Plug },
    name: "app",
    data() {
        return {
            isPlug: false,
            login: "Личный кабинет",
        };
    },
};
</script>
