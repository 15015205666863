<template>
    <section class="banner">
        <div class="banner__cols">
            <div class="banner__content">
                <h1 class="banner__title">{{ titleBanner }}</h1>
                <div class="banner__text">
                    {{ text }}
                </div>
                <div class="banner__ornament">
                    <img src="../assets/images/ornament.png" alt="ornament" />
                </div>
                <button
                    class="banner__button btn"
                    @click="$refs.reserve_popup.openPopup"
                    onclick="ym(95816218,'reachGoal','brone'); return true;"
                >
                    Забронировать стол
                </button>
            </div>
            <div class="banner__image"></div>
        </div>
    </section>
    <reserve-popup ref="reserve_popup" />
</template>

<script>
import ReservePopup from "./ReservePopup.vue";
export default {
    components: { ReservePopup },
    name: "main-page-banner",
    data() {
        return {
            title: `Встреча\n вкуса и искусства\n на одной тарелке`,
            title_m: `Встреча вкуса\n и искусства на одной\n тарелке`,
            text: `Халяль ресторан\n татарской кухни`,
        };
    },
    computed: {
        titleBanner() {
            return window.innerWidth <= 768 ? this.title_m : this.title;
        },
    },
};
</script>

<style lang="scss" scoped>
.banner {
    // background: url('../assets/images/banner-bg.png') no-repeat right #2A374B;
    background-color: #2a374b;
    position: relative;
    overflow: hidden;
    @media (max-width: 767px) {
        height: 640px;
        display: flex;
    }
    &__ornament {
        display: none;
        @media (max-width: 767px) {
            display: block;
            width: 72.62px;
            height: 12px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    &__cols {
        display: flex;
        width: 100%;
    }
    &__image {
        // background-color: #2A374B;
        background-image: url("../assets/images/banner-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 60vw;
        height: 100vh;
        position: relative;
        background-position: center;
        @media (max-width: 767px) {
            width: 100%;
            height: 100%;
            position: absolute;
            background-image: url("../assets/images/banner-bg-mobile.jpeg");
        }
    }
    // .banner__content
    &__content {
        color: #ffffff;
        width: 45vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 2;
        padding: 3rem;
        @media (max-width: 767px) {
            width: 100%;
            height: 100%;
            padding: 100px 0 60px;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: linear-gradient(
                0deg,
                rgba(42, 55, 75, 0.5) 100%,
                rgba(65, 85, 116, 0.5) 70%,
                rgba(99, 130, 177, 0) 0%
            );
        }
    }

    // .banner__text
    &__text {
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        line-height: 36px;
        color: #fff;
        font-size: clamp(12px, 5vw, 30px);
        display: flex;
        align-items: center;
        margin: 36px 0 60px 0;
        @media (max-width: 767px) {
            margin: 0 auto;
            color: #fff;
            padding: 10px 0;
            white-space: pre-line;
            p {
                text-shadow: 2px 2px 2px black;
            }
        }
    }

    // .banner__title
    &__title {
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        color: #fff;
        font-size: clamp(22px, 5vw, 55px);
        line-height: clamp(22px, 5vw, 64px);
        margin: 0;
        white-space: pre-line;
        @media (max-width: 767px) {
            color: #fff;
            order: 1;
            text-shadow: 2px 2px 2px black;
            flex: 1 1 auto;
            margin-top: 30px;
            font-size: 26px;
            line-height: 32px;
        }
    }

    // .banner__button
    &__button {
        color: #000;
        background-color: #fff;
        font-family: "Montserrat", sans-serif;
        width: fit-content;
        height: 80px;
        padding: 0 35px;
        font-weight: 600;
        font-size: 20px;
        @media (max-width: 767px) {
            margin-top: 2rem;
            order: 1;
            height: 64px;
            background-color: rgb(255, 255, 255, 0.6);
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #2a374b;
        }
    }
}
</style>
