<template>
    <section class="reservation">
        <div class="reservation-body">
            <h1 class="reservation-body__heading">{{ title }}</h1>
            <button
                class="reservation-body__btn"
                @click="$refs.reserve_popup.openPopup"
                onclick="ym(95816218,'reachGoal','brone'); return true;"
            >
                Забронировать стол
            </button>
        </div>

        <!-- <div class="reservation__body center-wrapper">
      <h2 class="reservation__title">
        Хотите посетить<br />
        ресторан?
      </h2>
      <div class="reservation__subtitle">
        <p>Забронируйте стол онлайн на нашем сайте</p>
      </div>
      <div class="reservation__btn">
        <button class="reservation__button" @click="$refs.reserve_popup.openPopup" onclick="ym(95816218,'reachGoal','brone'); return true;">Забронировать</button>
      </div>
    </div>
    <div class="reservation__image">
      <img src="../assets/images/reservation.png" alt="reservation" />
    </div> -->
    </section>
    <reserve-popup ref="reserve_popup" />
</template>

<script>
import ReservePopup from "./ReservePopup.vue";
export default {
    components: { ReservePopup },
    name: "main-page-reservation",
    data() {
        return {
            title: "Хотите посетить\n ресторан?",
        };
    },
};
</script>

<style lang="scss" scoped>
.reservation {
    background: url("../assets/images/reservation-bg-banner.png") no-repeat top #2a374b;
    background-size: 100%;
    background-blend-mode: soft-light;
    height: 380px;
    display: flex;
    align-items: center;
    padding: 40px 0;
    @media (max-width: 767px) {
        height: 384px;
        align-items: flex-start;
    }
}
.reservation-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: max-content;

    &__heading {
        // font-family: "Montserrat", sans-serif;
        font-size: 26px;
        font-weight: 600;
        line-height: 40px;
        color: #fff;
        text-align: center;
        @media (max-width: 768px) {
            white-space: pre-line;
        }
    }

    &__btn {
        // padding: 24px 38px;
        width: 296px;
        height: 80px;
        border-radius: 40px;
        border: none;
        // font-family: 'Montserrat';
        font-size: 20px;
        font-weight: 600;
        line-height: 40px;
        color: #2a374b;
        @media (max-width: 768px) {
            width: auto;
            height: 64px;
            font-size: 16px;
            line-height: 24px;
            padding: 0 30px;
        }
    }
}

@media (max-width: 767px) {
    .reservation {
        background: url("../assets/images/reservation-bg-mobile.png") no-repeat bottom #2a374b;
        background-size: cover;
    }
}
</style>
