<template>
    <section class="interior-section" id="photos">
        <div class="interior-carousel__desctop center-wrapper">
            <h1 class="interior-carousel__heading">Интерьер</h1>
            <swiper
                :modules="modules"
                :slides-per-view="3"
                :space-between="20"
                :scrollbar="{ draggable: true }"
                navigation
                :pagination="{ clickable: true }"
                :breakpoints="{
                    320: { spaceBetween: 0, slidesPerView: 1 },
                    769: { spaceBetween: 30, slidesPerView: 3 },
                }"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
            >
                <swiper-slide v-for="slide in slides" :key="slide.id" class="ai">
                    <img :src="require(`@/assets/images/photos-rest/${slide.img}`)" alt="slide" />
                </swiper-slide>
            </swiper>
        </div>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Scrollbar, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";
export default {
    components: { Swiper, SwiperSlide },
    data() {
        return {
            slides: [
                { id: 1, img: "1.jpg" },
                { id: 2, img: "2.jpg" },
                { id: 3, img: "3.jpg" },
                { id: 4, img: "4.jpg" },
                { id: 5, img: "5.jpg" },
            ],
            index: 1,
        };
    },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log("slide change");
        };
        return {
            onSwiper,
            onSlideChange,
            modules: [Scrollbar, Navigation, Pagination],
        };
    },
};
</script>

<style lang="scss">
$item_width: 380;
@function body-width() {
    @return $item-width * 3 + 80;
}
.interior-section {
    padding: 50px 0 100px;
    @media (max-width: 767px) {
        padding: 50px 0 60px;
    }
    .swiper {
        padding: 0 80px 50px;
        @media (max-width: 767px) {
            padding: 0;
            .swiper-wrapper {
                padding-bottom: 40px;
            }
        }
        .swiper-slide {
            border-radius: 16px;
            overflow: hidden;
            @media (max-width: 767px) {
                height: 402px;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .swiper-button-next,
        .swiper-button-prev {
            width: 44px;
            height: 44px;
            background: #fff;
            border-radius: 50%;
            @media (max-width: 767px) {
                display: none;
            }
            &:after,
            &:after {
                font-size: 16px;
                color: #2a374b;
                font-weight: bold;
            }
        }
        .swiper-scrollbar {
            display: flex;
            align-items: center;
            @media (max-width: 767px) {
                display: none;
            }
            .swiper-scrollbar-drag {
                background: #2a374b;
                border: 4px solid #2a374b;
                border-radius: 0;
            }
        }
        .swiper-pagination {
            display: none;
            justify-content: center;
            align-items: center;
            @media (max-width: 767px) {
                display: flex;
            }
            .swiper-pagination-bullet {
                width: 6.67px;
                height: 6.67px;
                background: #2a374b;
                opacity: 0.1;
                &.swiper-pagination-bullet-active {
                    width: 10px;
                    height: 10px;
                    opacity: 1;
                }
            }
        }
    }
}
.indicator {
    background: none;
    border: none;
    outline: none;
}
.indicator-next {
    transform: rotate(180deg);
}
.carousel__desctop {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    width: max-content;
    margin: 0 auto;
    margin-bottom: 80px;

    @media (max-width: 767px) {
        display: none;
    }
}
.interior-carousel__heading {
    text-align: center;
    font-weight: 600;
    font-size: 48px;
    @media (max-width: 767px) {
        text-align: left;
        font-size: 26px;
    }
}
.carousel-body {
    display: flex;
    flex-direction: row;
    width: body-width() * 1px;
    overflow-x: scroll;
    padding-bottom: 40px;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        height: 4px;
    }
    &::-webkit-scrollbar-track {
        background-color: #b0b0b0;
        border-radius: 2px;
        // width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #2a374b;
        border-radius: 2px;
    }

    &__item {
        width: $item_width + px !important;
        height: 544px;
        border-radius: 16px;
        flex: 0 0 auto;
        &:not(:first-child) {
            margin-left: 24px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: inherit;
        }
        @media (max-width: 767px) {
            width: 100% !important;
            height: 402px;
        }
    }

    @media (max-width: 1300px) {
        width: 90vw;
    }
}
.carousel {
    width: max-content;
    margin: 0 auto;
}
.carouselExampleIndicators {
    display: none;
    margin: 0 auto;

    .carousel-inner {
        width: 312px;
    }
}

.interior-carousel__mobile {
    display: none;
}
@media (max-width: 767px) {
    .interior-carousel__mobile {
        margin-bottom: 60px;
        display: block;
    }
}
</style>
