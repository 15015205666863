<template>
    <section class="location-sect" id="contacts">
        <img class="tubetey-bg-mb" src="../assets/images/tubetey-bg-mb.png" />
        <div class="wrapper">
            <div class="location-sect__info">
                <h2>Как нас найти?</h2>
                <ul>
                    <li>
                        <h1>1</h1>
                        <p>Зайдите в БЦ «Территория 3000» со входа<br />«Темная сторона» (ул. Генерала Горбатова, 2)</p>
                    </li>
                    <li>
                        <h1>2</h1>
                        <p>Поверните направо (к лифтам)<br />и поднимитесь на 8 этаж</p>
                    </li>
                    <li>
                        <h1>3</h1>
                        <p>Выходя из лифта поверните налево,<br />на нашу террасу</p>
                    </li>
                </ul>
            </div>

            <div class="location-sect__map">
                <div class="map-info-block">
                    <h4>Адрес</h4>
                    <h3>г. Уфа, ул. Генерала Горбатова, 2</h3>
                    <h4>Телефон</h4>
                    <a href="tel:89273546263">+7 927 354-62-63</a>
                    <h4>График работы</h4>
                    <table>
                        <tr v-for="(el, index) in working_time" :key="index">
                            <td>{{ el.day }}</td>
                            <td>{{ el.time }}</td>
                        </tr>
                    </table>
                    <button class="make_way">
                        <a
                            href="https://yandex.ru/maps/172/ufa/?ll=56.008466%2C54.717511&mode=routes&rtext=~54.717660%2C56.008239&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D62087901456&z=16.63"
                        >
                            <h3>Проложить маршрут</h3>
                            <img src="../assets/images/arrow-back.svg" />
                        </a>
                    </button>
                </div>
                <iframe
                    src="https://yandex.ru/map-widget/v1/?um=constructor%3Abf8d73893169945dadf6d099bc041e8dfd6b30274d2221feba21833e91bf0c75&amp;source=constructor"
                    frameborder="0"
                    class="md"
                ></iframe>
                <iframe
                    src="https://yandex.ru/map-widget/v1/?um=constructor%3A1e0b997a2dc62d8a37e2977db960be5cc262056a27d174495e049eb0ff6d8db6&amp;source=constructor"
                    width="100%"
                    height="720"
                    frameborder="0"
                    class="mm"
                ></iframe>
            </div>
            <div class="location-sect__footer">
                <div class="response-block">
                    <div class="response-block__row">
                        <img class="response-block__row_mapName" src="../assets/images/yandex-vector.svg" />
                        <a
                            href="https://yandex.ru/maps/org/zaman_by_tubatay/62087901456/?ll=56.008466%2C54.717511&z=16"
                            target="_blank"
                        >
                            Прочитать отзывы
                            <img class="go-arrow" src="../assets/images/svg/arrow_back.svg" />
                        </a>
                    </div>
                    <div class="response-block__row">
                        <img class="response-block__row_mapName" src="../assets/images/twoGis-vector.svg" />
                        <a href="https://2gis.ru/ufa/firm/70000001079857981" target="_blank">
                            Прочитать отзывы
                            <img class="go-arrow" src="../assets/images/svg/arrow_back.svg" />
                        </a>
                    </div>
                </div>
                <div class="make-response-block">
                    <button class="make-response" @click="isPopupFeedback = true">Оставить отзыв</button>
                </div>
            </div>
        </div>
    </section>
    <feedback-popup v-if="isPopupFeedback" @close="isPopupFeedback = false" />
</template>

<script>
import FeedbackPopup from "./FeedbackPopup.vue";
export default {
    components: {
        FeedbackPopup,
    },
    data() {
        return {
            isPopupFeedback: false,
            working_time: [
                {
                    day: "ПН-ЧТ",
                    time: "11:00 - 22:00",
                },
                {
                    day: "ПТ-СБ",
                    time: "11:00 - 23:00",
                },
                {
                    day: "ВС",
                    time: "11:00 - 22:00",
                },
            ],
        };
    },
};
</script>

<style lang="scss">
@mixin flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}
@mixin flex-col {
    display: flex;
    flex-direction: column;
}

.wrapper {
    @media (max-width: 767px) {
        padding: 0 24px 64px 24px;
    }
    // @media (max-width: 650px) {
    //     padding-top: 80%;
    // }
    // @media (max-width: 450px) {
    //     padding-top: 70%;
    // }
}

.tubetey-bg-mb {
    // position: absolute;
    // top: -20%;
    width: 100vw;

    @media (min-width: 767px) {
        display: none;
    }
    // @media (max-width: 650px) {
    //     top: -10%;
    // }
}

.location-sect__footer {
    @include flex-row();
    gap: 96px;
    width: max-content;
    margin: 0 auto;
    margin-top: 94px;

    @media (max-width: 767px) {
        flex-direction: column;
        gap: 36px;
        margin: 0;
        margin-top: 52px;
        width: 100%;
    }

    .response-block {
        @include flex-col();
        gap: 25px;
        width: 312px;
        @media (max-width: 767px) {
            width: 100%;
        }

        &__row {
            @include flex-row();
            justify-content: space-between;
            width: 100%;

            @media (max-width: 767px) {
                justify-content: space-between;
                gap: unset;
            }

            a {
                text-decoration: none;
                color: #fff;
                @include flex-row();
                gap: 8px;
                font-size: 14px;
            }

            .go-arrow {
                transform: rotate(180deg);
                height: 10px;
            }
        }
    }

    .make-response {
        width: 312px;
        height: 64px;
        background: none;
        border-radius: 32px;
        border: 1px solid #fff;

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    .make-response-block {
        @media (max-width: 767px) {
            width: 100%;
        }
    }
}

.location-sect {
    background: url("../assets/images/tubetey-bg.png") no-repeat top right #2a374b;
    color: #fff;
    background-size: 50%;
    padding: 80px 56px;

    @media (max-width: 767px) {
        padding: 0;
        background: #2a374b;
        position: relative;
        border-radius: 50vw 50vw 0 0;
    }

    &__info {
        h1 {
            font-size: 64px;
            margin: 0;
            width: 42px;
        }
        p {
            margin: 0;
            line-height: 28px;
            font-weight: 100;
            font-size: 18px;
        }
        ul {
            padding: 0;
            margin-bottom: 96px;

            li {
                list-style: none;
                display: flex;
                flex-direction: row;
                height: max-content;
                align-items: center;
                gap: 36px;
                margin-bottom: 28px;

                &:last-child {
                    margin: 0;
                }
            }
        }
    }

    &__map {
        width: 100%;
        height: 800px;
        margin: 0 auto;
        border-radius: 16px;
        position: relative;

        iframe {
            width: 100%;
            height: 100%;
            border-radius: 16px;
        }

        @media (max-width: 767px) {
            height: 817px;
            width: 100%;

            iframe {
                width: 100%;
                height: 100%;
                border-radius: 16px;
            }
        }
    }
}
.map-info-block {
    position: absolute;
    top: 0;
    bottom: 0;
    height: max-content;
    margin: auto 0;
    left: 100px;
    color: #2a374b;
    border-radius: 16px;
    padding: 64px;
    width: max-content;
    background-color: hsla(0, 0%, 100%, 0.5);
    backdrop-filter: blur(20.100000381469727px);
    box-shadow: 2px 4px 30px 0px #5942350d;

    @media (max-width: 767px) {
        width: 100%;
        left: 0;
        bottom: 0;
        top: auto;
        padding: 36px;
    }

    h3,
    a {
        margin-bottom: 36px;
        font-size: 20px;
    }
    a {
        display: block;
        text-decoration: none;
        color: inherit;
    }
    h4 {
        font-size: 18px;
        margin-bottom: 20px;
    }
    table {
        margin-bottom: 36px;
        width: 100%;

        td:last-child {
            text-align: right;
        }
    }

    .make_way {
        height: 80px;
        padding: 0 30px;
        background: none;
        border-radius: 40px;
        @media (max-width: 767px) {
            width: 100%;
            height: 64px;
            padding: 0;
            border: none;
            img {
                display: none;
            }
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 50px;
            text-decoration: none;
            color: inherit;
            height: 100%;
            @media (max-width: 767px) {
                border: 1.5px solid #2a374b;
                border-radius: inherit;
            }
        }

        h3 {
            margin: 0;

            @media (max-width: 767px) {
                font-size: 16px;
                width: max-content;
            }
        }

        img {
            transform: rotate(180deg);
        }
    }
}
</style>
